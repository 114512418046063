<template>
  <div
    id="competition-type2"
    :class="data.block"
    class="competition h-96px my-2"
  >
    <swiper
      ref="slider"
      class="swiper h-100"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="competition in competitions"
        :key="competition._id"
        class="card1"
      >
        <!-- 比賽名稱 -->
        <div class="competion__title d-flex px-4 mt-2">
          <v-img
            max-width="20"
            max-height="20"
            :src="competition.game_logo"
          />
          <span class="caption title--text ml-1 font-weight-bold">{{ competition.competition_name }}</span>
        </div>

        <!-- 比賽內容 -->
        <div class="competion__content d-flex justify-space-between align-center px-4 h-68px">
          <!-- 主場 -->
          <div class="competion__content-home w-40 d-flex align-center">
            <v-img
              max-width="32"
              max-height="32"
              :src="competition.home.team_logo"
            />
            <span class="caption title--text ml-2">{{ competition.home.team_name }}</span>
          </div>

          <!-- 比分 / vs -->
          <div class="competion__content-num primary--text font-weight-bold">
            {{ !competition.match_scoreline ? 'VS' : competition.match_scoreline }}
          </div>

          <!-- 客場 -->
          <div class="competion__content-away w-40 d-flex justify-end align-center text-right">
            <span class="caption title--text mr-2">{{ competition.away.team_name }}</span>
            <v-img
              max-width="32"
              max-height="32"
              :src="competition.away.team_logo"
            />
          </div>
        </div>
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination"
        :style="customPaginationColor"
      />
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({}),

  // 賽事資料
  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    competitions() {
      return this.data.data[this.data.type].slice(0, 5)
    },

    swiperOption() {
      return {
        autoplay: {
          delay: 3000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
    },

    // item background
    bg() {
      return {
        light: { background: 'rgba(206, 206, 206, 0.12)' },
        dark: { background: 'rgba(0, 0, 0, 0.25)' },
      }
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.competition {
	overflow: auto;
}

.h-96px {
	height: 96px;
}

.h-68px {
	height: 68px;
}

.w-40 {
	width: 40%;
}

.competion__content {
	&-home,
	&-away {
		.caption {
			line-height: 14px;
		}
	}
}

.swiper {
	.swiper-pagination {
		top: 4px;
		margin-left: -16px;
		text-align: right;
	}
}
</style>
